import LocaleMessages from '@/locale/LocaleMessages'

const nl:LocaleMessages = {
  application: {
    title: 'Locatie beheer',
    logout: 'Uitloggen',
    menu: {
      locations: 'Locaties',
      reservations: 'Inschrijvingen',
      waitingListPropositions: 'Wachtlijst aanbiedingen',
      analysis: 'Analyse',
      audit: 'Logboek',
      settings: 'Instellingen'
    }
  },
  home: {
    title1: 'Welkom op het',
    title2: 'WhyPlan Portaal'
  },
  servicePicker: {
    searchPlaceholder: 'Kies een locatie',
    noData: 'Geen locaties gevonden'
  },
  propositionStatusPicker: {
    statusSelection: 'Status van aanbieding',
    searchPlaceholder: 'Kies een Status',
    noData: 'Geen status gevonden'
  },
  servicePlannerPicker: {
    label: 'Gekoppelde planner aan locatie',
    searchPlaceholder: 'Kies een planner',
    noData: 'Geen planner gevonden'
  },
  causedByReasonPicker: {
    placeholder: 'Reden',
    causedByReasonError: 'Selecteer een reden',
    causedByReasonCommentPlaceholder: 'Licht hier je reden toe (optioneel)'
  },
  autocomplete: {
    noSearchResults: 'Geen resultaten gevonden',
    noData: 'Geen items gevonden'
  },
  searchField: {
    placeholder: 'Zoeken...'
  },
  table: {
    searchLabel: 'Zoeken...',
    loadingText: 'Bezig met laden...',
    noDataText: 'Geen rijen om weer te geven',
    noResultsText: 'Geen resultaten gevonden met de huidige zoektermen',
    itemsPerPageText: 'Rijen per pagina',
    itemsPerPageAllText: 'Alles',
    pageText: '{0}-{1} van de {2}',
    actions: 'Acties'
  },
  notification: {
    overwriteMessage: 'Wilt u de bestaande data overschrijven?',
    serviceOffer: {
      pincodeOverwrite: 'Wilt u de optimale bezetting overschrijven?',
      pinCodeOverwriteErrorTitle: 'Aanpassing pincode niet mogelijk',
      pinCodeOverwriteGenericError: 'De gekozen pincodes kunnen niet gewijzigd worden (kunnen wel gesloten worden), omdat dit de boekbaarheid van één of meerdere producten beïnvloedt. Deze producten zijn momenteel boekbaar en/of gebruikt in de rekentool en/of hebben openstaande boekingen en wachtlijstaanbiedingen.'
    },
    delete: 'Wilt u deze rij verwijderen?',
    overwrite: 'Overschrijven',
    cancel: 'Annuleren',
    close: 'Sluiten',
    back: 'Terug',
    ok: 'Oke'
  },
  pages: {
    ServiceOffer: {
      offer: 'Aanbod',
      dateSelection: 'Datum kiezen',
      day: {
        0: 'zo',
        1: 'ma',
        2: 'di',
        3: 'wo',
        4: 'do',
        5: 'vr',
        6: 'za'
      },
      capacity: 'Cap.',
      optimalOccupancy: 'Optimale bezetting',
      closedGroup: 'Gesloten groep',
      occupancyPercentageAverageTooltip: 'Gemiddelde bezettingsgraad op {dayOfWeek} tussen {from} en {until} ({amountOfMonths} maanden), gebaseerd op pm-budget.',
      pincodeInFutureTooltip: 'Geplande pincode<br />{pincode}<br />start op {date}, {dayOfWeek}',
      available: 'Vrij',
      year: 'jaar',
      groups: 'Groepen',
      legend: {
        availableSeat: 'Beschikbaar stoeltje',
        reservedSeat: 'Gereserveerd stoeltje',
        takenSeat: 'Bezet stoeltje',
        seatWithinPincode: 'Stoeltje binnen pincode',
        flexSeat: 'Flexreservering'
      },
      ageCategory: {
        '0-12': '0-1', // ZeroToOne
        '12-24': '1-2', // OneToTwo
        '24-36': '2-3', // TwoToThree
        '36-48': '3-4', // ThreeToFour
        '48-84': '4-7', // FourToSeven
        '84-9999': '7+', // SevenPlus
        ZeroToOne: '0-1',
        OneToTwo: '1-2',
        TwoToThree: '2-3',
        ThreeToFour: '3-4',
        FourToSeven: '4-7',
        SevenPlus: '7+'
      },
      placementPolicy: {
        flexible: 'Soepel',
        moderate: 'Gematigd',
        strict: 'Streng',
        flexibleTooltip: 'Soepel plaatsingsbeleid (Geen impact)',
        moderateTooltip: 'Gematigd plaatsingsbeleid (Halve impact)',
        strictTooltip: 'Streng plaatsingsbeleid (Volledige impact)'
      },
      seatCell: {
        unavailable: 'Niet beschikbaar',
        childNumber: 'Kindnummer',
        name: 'Naam',
        dateOfBirth: 'Geboortedatum'
      },
      childrenTable: {
        heading: 'Bezet {ageCategory} jaar',
        headingFlex: 'Flex {ageCategory} jaar',
        noReservations: 'Geen reserveringen',
        reservedSeat: 'Gereserveerd',
        columns: {
          childName: 'Kind naam',
          childNumber: 'Kindnummer',
          dateOfBirth: 'Geboortedatum',
          morning: 'Ochtend',
          afternoon: 'Middag'
        }
      }
    },
    audit: {
      pageTitle: 'Logboek',
      filters: {
        category: {
          header: 'Type',
          placeholder: 'Kies een type',
          filterProduct: 'Product'
        },
        actor: {
          header: 'Gewijzigd door',
          placeholder: 'Kies gebruiker',
          systemUser: 'Systeem'
        },
        period: {
          header: 'Periode waarin gewijzigd',
          placeholderFrom: 'Start datum',
          placeholderUntil: 'Eind datum'
        },
        product: {
          changeType: {
            header: 'Wijziging',
            placeholder: 'Kies wijziging',
            filterChangedIsActive: 'Aan/uit locatie',
            filterChangedTextualAddition: 'Productnotitie',
            filterChangedProductInCalculator: 'Rekentool'
          },
          serviceVariety: {
            header: 'Opvangsoort',
            placeholder: 'Kies opvang',
            filterKDV: 'Kinderdagverblijf',
            filterNSO: 'Naschoolse opvang',
            filterVSO: 'Voorschoolse opvang'
          },
          product: {
            header: 'Product',
            placeholder: 'Kies product'
          }
        }
      },
      table: {
        headers: {
          date: 'Gewijzigd op',
          type: 'Type',
          change: 'Wijziging',
          location: 'Locatie',
          actor: 'Gewijzigd door'
        },
        auditDetails: {
          typeNames: {
            product: 'Product'
          },
          product: {
            productAndServiceVarietyHeading: 'Productnaam + opvangsoort',
            noteHeading: 'Productnotitie',
            noteOld: 'Oud:',
            noteNew: 'Nieuw:',
            itemHeadingProductActivatedForLocation: 'Product geactiveerd op locatie:',
            itemHeadingProductDeactivatedForLocation: 'Product verwijderd van locatie:',
            itemHeadingProductActivatedForCalculator: 'Product geactiveerd in rekentool:',
            itemHeadingProductDeactivatedForCalculator: 'Product uitgeschakeld in rekentool:',
            itemHeadingProductNoteActivated: 'Productnotitie geactiveerd in Kieswijzer:',
            itemHeadingProductNoteDeactivated: 'Productnotitie uitgeschakeld in Kieswijzer:',
            itemHeadingProductNoteChanged: 'Productnotitie gewijzigd'
          },
          actors: {
            deleted: 'Verwijderde gebruiker',
            system: 'Systeem'
          }
        }
      }
    },
    settings: {
      pageTitle: 'Instellingen',
      globalActions: 'Globale acties',
      integrations: 'Integraties',
      export: 'Export',
      actions: {
        sendEmails: {
          header: 'Geplande emails',
          buttonText: 'Verstuur alle geplande emails',
          error: 'Er is iets misgegaan bij het versturen van de geplande emails!'
        },
        sendWaitingListActualisationEmails: {
          header: 'Wachtlijst herinneringsmails',
          buttonText: 'Verstuur alle herinneringsmails',
          error: 'Er is iets misgegaan bij het versturen van de herinneringsmails!'
        },
        exportServiceSettings: {
          header: 'Locatie instellingen',
          buttonText: 'Exporteer alle locatie instellingen',
          error: 'Er is iets misgegaan bij het exporteren van het bestand!'
        }
      }
    }
  },
  form: {
    isRequired: 'Dit veld is verplicht',
    tooLong: '{maxLength} karakterlimiet overschreden'
  },
  days: {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag'
  },
  daysShort: {
    monday: 'ma',
    tuesday: 'di',
    wednesday: 'wo',
    thursday: 'do',
    friday: 'vr',
    saturday: 'za',
    sunday: 'zo'
  },
  openingTimes: {
    closed: 'Gesloten',
    unknown: 'Onbekend'
  },
  serviceType: {
    dayCare: 'Buitenschoolse opvang',
    toddlerCare: 'Voortgezet speciaal onderwijs',
    schoolCare: 'Kinderdagverblijf '
  },
  registrations: {
    table: {
      searchLabel: 'Zoeken op locatie of kind...',
      heading: 'Inschrijvingen',
      daysOfCare: 'Plaatsingen',
      daysOfWaitinglist: 'Wachtlijst',
      childNumberUnavailable: 'Niet beschikbaar',
      columns: {
        childId: 'Kind ID',
        childName: 'Kind naam',
        childNumber: 'Kindnummer',
        dateOfBirth: 'Geboortedatum',
        createdAt: 'Geboekt op',
        validFrom: 'Geldig vanaf',
        validUntil: 'Geldig tot',
        location: 'Locatie',
        days: 'Ingeschreven dagen',
        serviceVarieties: 'Opvangsoort(en)',
        status: 'Status',
        lastName: 'Achternaam'
      },
      status: {
        bookingStatus: 'Status Boeking',
        pending: 'Nieuwe inschrijving',
        contractDeclinedByParent: 'Afgewezen door ouder',
        contractDeclinedByPlanner: 'Afgewezen in Flexkids',
        expired: 'Verlopen',
        completed: 'Afgerond',
        expanded: {
          email: 'Email geverifieerd',
          contract: 'Contract getekend',
          mandate: 'Mandaat getekend',
          pending: 'In Behandeling',
          completed: 'Compleet',
          cancelled: 'Geannuleerd',
          overdue: 'Verlopen',
          reserved: 'Gereserveerd'
        },
        sync: {
          syncStatus: 'Inschrijving verwerkt in Flexkids?',
          synced: 'Ja',
          notSynced: 'Nee'
        }
      },
      stepper: {
        email: 'E-mail',
        contract: 'Overeenkomst',
        SEPA: 'Machtiging'
      }
    }
  },
  waitingListPropositions: {
    table: {
      searchLabel: 'Zoeken op locatie of kind...',
      heading: 'Wachtlijst aanbiedingen',
      daysOfCare: 'Plaatsingen',
      daysOfWaitinglist: 'Wachtlijst',
      childNumberUnavailable: 'Niet beschikbaar',
      parent: 'Ouder',
      planner: 'Planner',
      expanded: {
        childName: 'Roepnaam',
        priority: 'Prioriteit',
        expiresOn: 'Verloopt op',
        proposedOn: 'Uitgestuurd op',
        propose: 'Aanbieden',
        cancel: 'Afwijzen',
        revoke: 'Intrekken',
        extend: 'Verlengen',
        confirmCancelButton: 'Ja, wijs aanbod af',
        confirmRevokeButton: 'Ja, trek aanbod in',
        cancelDialogTitle: 'Afwijzen nieuw aanbod?',
        revokeDialogTitle: 'Intrekken uitgestuurd aanbod?',
        extendDialogTitle: 'Verlengen aanbod?',
        confirmExtendButton: 'Ja, verleng aanbod',
        cancelHandling: 'Annuleren',
        canceledByPlannerTitle: 'Reden voor afwijzen door planner',
        revokedByPlannerTitle: 'Reden voor intrekken door planner',
        canceledByParentTitle: 'Reden voor afwijzen door ouder',
        proposeCheck: 'Weet je zeker dat je het aanbod van <b>{name}</b> wilt aanbieden?',
        cancelCheck: 'Weet je zeker dat je het aanbod van <b>{name}</b> wilt weigeren?',
        cancelReason: 'Wat is de reden dat je dit aanbod wilt weigeren?',
        revokeCheck: 'Weet je zeker dat je het aanbod van <b>{name}</b> wilt intrekken?',
        revokeReason: 'Wat is de reden dat je dit aanbod wilt intrekken?',
        extendDescription: 'Weet je zeker dat je het aanbod van <b>{name}</b> wilt verlengen?',
        extendDateLabel: 'Stel een verval datum in voor het aanbod.',
        propositionAlreadyExtended: 'Aanbod is al eens verlengd, aanbod mag maar 1x verlengd worden.',
        expireDate: 'Kies vervaldatum',
        startKDV: 'KDV startdatum',
        startNSO: 'NSO startdatum',
        startVSO: 'VSO startdatum',
        products: 'Product(en)',
        productUnsupported: 'Aanbieden niet mogelijk wegens niet-boekbaar product.',
        group: 'Groep'
      },
      columns: {
        childId: 'Kind ID',
        childName: 'Kind naam',
        childNumber: 'Kindnummer',
        createdAt: 'Aangemaakt op',
        dateOfBirth: 'Geboortedatum',
        startDate: 'Startdatum',
        location: 'Locatie',
        days: 'Aangeboden dagen',
        planner: 'Gekoppelde planner',
        serviceVarieties: 'Opvangsoort(en)',
        status: 'Status',
        lastName: 'Achternaam'
      },
      status: {
        bookingStatus: 'Status Boeking',
        email: 'Email geverifieerd ',
        contract: 'Contract getekend',
        mandate: 'Mandaat getekend',
        pending: 'In behandeling bij ouder',
        completed: 'Compleet',
        cancelled: 'Geannuleerd',
        overdue: 'Verlopen',
        reserved: 'Gereserveerd',
        sync: {
          syncStatus: 'Inschrijving verwerkt in Flexkids?',
          synced: 'Ja',
          notSynced: 'Nee'
        }
      },
      priority: {
        ownstaff: 'Eigen personeel',
        smi: 'SMI',
        secondchild: 'Tweede kind',
        daycaretoschoolcare: 'KDV > BSO',
        expansion: 'Uitbreiding',
        waitinglistfamily: 'Wachtlijst gezin',
        movehouse: 'Verhuizing',
        new: 'Nieuwe inschrijving',
        changedays: 'Wisseling dagen',
        changegroup: 'Wisseling groep',
        changelocation: 'Wisseling locatie',
        extension: 'Verlenging',
        sleepingwaitinglist: 'Slapende wachtlijst',
        vveindication: 'VVE indicatie',
        povwithoutkot: 'POV zonder KOT'
      },
      stepper: {
        email: 'E-mail',
        contract: 'Overeenkomst',
        SEPA: 'Machtiging'
      }
    }
  },
  services: {
    form: {
      isOptionallyRequired: 'Dit veld is verplicht als je deze locatie wilt kunnen tonen in de kieswijzer',
      atLeastOneMedia: 'De locatie heeft minimaal één foto of video nodig als je deze locatie wilt kunnen tonen in de kieswijzer'
    },
    menu: {
      information: 'Informatie',
      offer: 'Aanbod',
      waitingList: 'Wachtlijst',
      attributes: 'Kenmerken',
      products: 'Producten',
      controlVariables: 'Instellingen',
      guideAndReservation: 'rondleiding en reservering',
      activations: 'Activeringen'
    },
    title: 'Locatiebeheer',
    searchPlaceholder: 'Locatie kiezen',
    serviceActivations: {
      title: 'Activeringen',
      benefitCalculator: {
        title: 'Rekentool',
        enable: {
          title: 'Beschikbaar in de rekentool',
          description: 'Maak deze locatie beschikbaar in de rekentool voor ouders.',
          errorText: 'Om deze optie te activeren, stel eerst alle verplichte instellingen in:'
        },
        activateDialog: {
          title: 'Beschikbaar in rekentool activeren?',
          text: 'Weet je zeker dat je deze locatie beschikbaar wilt maken in de rekentool?',
          confirmButton: 'Ja, activeer',
          cancelButton: 'Terug'
        },
        deactivateDialog: {
          title: 'Beschikbaar in rekentool uitschakelen?',
          text: 'Weet je zeker dat je deze locatie niet meer beschikbaar wilt maken in de rekentool?',
          confirmButton: 'Ja, schakel uit',
          cancelButton: 'Terug'
        },
        checklist: {
          activeBookableProducts: 'Actieve, boekbare producten instellen',
          calculatorProducts: 'Producten in de rekentool instellen',
          configureSubscriptionsOrProducts: 'Abonnement(en) instellen of Flexkids producten activeren'
        }
      },
      waitingListManagement: {
        title: 'Wachtlijst beheer',
        waitingListActualization: {
          title: 'Wachtlijst actualisatie',
          description: 'Houd de wachtlijst up-to-date.',
          errorText: 'Om deze optie te activeren, stel eerst alle verplichte instellingen in:',
          activateDialog: {
            title: 'Wachtlijst actualisatie activeren?',
            text: 'Weet je zeker dat je de wachtlijst actualisatie wilt activeren?',
            confirmButton: 'Ja, activeer',
            cancelButton: 'Terug'
          },
          deactivateDialog: {
            title: 'Wachtlijst actualisatie uitschakelen?',
            text: 'Weet je zeker dat je de wachtlijst actualisatie wilt uitschakelen?',
            confirmButton: 'Ja, schakel uit',
            cancelButton: 'Terug'
          },
          checklist: {
            activeBookableProducts: 'Actieve, boekbare producten instellen',
            configureSubscriptionsOrProducts: 'Abonnement(en) instellen of Flexkids producten activeren',
            actualizationPeriods: 'Wachtlijst actualisatie termijnen instellen'
          }
        },
        waitingListValidation: {
          title: 'Valideer wachtlijst aanbiedingen',
          description: 'Keur wachtlijstaanbiedingen handmatig goed via een planner in plaats van automatische goedkeuring door Whyplan.',
          activateDialog: {
            title: 'Wachtlijst validatie activeren?',
            text: 'Weet je zeker dat je de wachtlijstaanbiedingen handmatig goed wilt keuren via een planner?',
            confirmButton: 'Ja, activeer',
            cancelButton: 'Terug'
          },
          deactivateDialog: {
            title: 'Wachtlijst validatie uitschakelen?',
            text: 'Weet je zeker dat je de wachtlijst validatie wilt uitschakelen?',
            confirmButton: 'Ja, schakel uit',
            cancelButton: 'Terug'
          },
          warningDialog: {
            title: 'Aanbiedingen vanaf de wachtlijst',
            text: 'Er zijn nog aanbiedingen vanaf de wachtlijst die wachten op een reactie van een planner. Deze worden niet automatisch afgehandeld. Zorg ervoor dat een planner deze aanbiedingen gaat bekijken.'
          }
        }
      },
      selectionGuideManagement: {
        title: 'Kieswijzer beheer',
        visible: {
          title: 'Zichtbaar in Kieswijzer',
          description: 'Maak deze locatie zichtbaar in Kieswijzer voor ouders.',
          errorText: 'Om deze optie te activeren, stel eerst alle verplichte instellingen in:',
          activateDialog: {
            title: 'Zichtbaar in Kieswijzer activeren?',
            text: 'Weet je zeker dat je deze locatie zichtbaar wilt maken in de Kieswijzer?',
            confirmButton: 'Ja, activeer',
            cancelButton: 'Terug'
          },
          deactivateDialog: {
            title: 'Zichtbaar in Kieswijzer uitschakelen?',
            text: 'Weet je zeker dat je deze locatie niet meer zichtbaar wilt maken in de Kieswijzer?',
            confirmButton: 'Ja, schakel uit',
            cancelButton: 'Terug'
          },
          checklist: {
            serviceDescription: 'Locatie omschrijving instellen',
            serviceMedia: 'Foto\'s en/of video\'s toevoegen',
            serviceProperties: 'Kenmerken instellen',
            serviceOpeningTimes: 'Openingstijden instellen'
          }
        },
        bookable: {
          title: 'Inschrijven via Kieswijzer',
          description: 'Activeer zodat ouders zich in kunnen schrijven op deze locatie.',
          errorText: 'Om deze optie te activeren, stel eerst alle verplichte instellingen in:',
          activateDialog: {
            title: 'Inschrijven via Kieswijzer activeren?',
            text: 'Weet je zeker dat je inschrijven via de Kieswijzer wilt activeren?',
            confirmButton: 'Ja, activeer',
            cancelButton: 'Terug'
          },
          deactivateDialog: {
            title: 'Inschrijven via Kieswijzer uitschakelen?',
            text: 'Weet je zeker dat je inschrijven via de Kieswijzer wilt uitschakelen? Dit betekent ook dat aanbiedingen vanuit de wachtlijst en directe plaatsing uitgeschakeld zal zijn.',
            confirmButton: 'Ja, schakel uit',
            cancelButton: 'Terug'
          },
          checklist: {
            activeBookableProducts: 'Actieve, boekbare producten instellen',
            configureSubscriptionsOrProducts: 'Abonnement(en) instellen of Flexkids producten activeren',
            pinCodes: 'Pincodes instellen',
            flexMargin: 'Flex marge instellen',
            planningHorizon: 'Plannings horizon instellen'
          }
        }
      },
      offersAndPlacements: {
        title: 'Aanbiedingen en plaatsingen',
        errorText: 'Schakel eerst \'Inschrijven via Kieswijzer\' in om aanbiedingen en plaatsingen te activeren.',
        enable: {
          title: 'Aanbiedingen vanuit de wachtlijst en directe plaatsing',
          description: 'Activeer deze optie om Whyplan aanbiedingen vanuit de wachtlijst te doen en ouders direct beschikbare plekken te laten reserveren. Schakel uit om de locatie te pauzeren.',
          activateDialog: {
            title: 'Aanbiedingen en plaatsingen activeren?',
            text: 'Weet je zeker dat je aanbiedingen en plaatsingen wilt activeren?',
            confirmButton: 'Ja, activeer',
            cancelButton: 'Terug'
          },
          deactivateDialog: {
            title: 'Aanbiedingen en plaatsingen uitschakelen?',
            text: 'Weet je zeker dat je aanbiedingen en plaatsingen vanuit Whyplan wilt uitschakelen?',
            confirmButton: 'Ja, schakel uit',
            cancelButton: 'Terug'
          },
          checklist: {
            activeBookableProducts: 'Actieve, boekbare producten instellen',
            configureSubscriptionsOrProducts: 'Abonnement(en) instellen of Flexkids producten activeren',
            pinCodes: 'Pincodes instellen',
            placementPolicy: 'Plaatsingsbeleid instellen',
            flexMargin: 'Flex marge instellen',
            planningHorizon: 'Plannings horizon instellen'
          }
        }
      },
      checklist: {
        optionalItem: 'Optioneel',
        noRights: 'De rechten om dit in te stellen liggen bij een ander.',
        defaultSetting: 'De standaard instelling is actief.',
        customSetting: 'De standaard instelling is overschreven.'
      },
      backNavigation: {
        text: 'Ga terug naar Activeringen'
      }
    },
    serviceDetail: {
      waitingListAutomationInfo: 'Wachtlijst automatiseren niet mogelijk. Klik voor meer info.',
      serviceInformation: 'Locatie informatie',
      form: {
        information: 'Informatie',
        address: 'Adres',
        name: 'Naam',
        description: 'Omschrijving',
        email: 'E-mail',
        phoneNumber: 'Telefoonnummer',
        price: 'Uurtarief',
        pricingInvalidValue: 'Vul dit veld in om deze locatie actief te kunnen maken voor de Kieswijzer',
        bookingsUrl: 'Url voor boeken rondleidingen',
        tourUrl: 'Url voor rondleidingen'
      },
      openingTimes: {
        openingTimesTitle: 'Openingstijden',
        openingTimesTitleEdit: 'Openingstijden overschrijven',
        description: 'Standaard zijn de openingstijden van deze locatie gebaseerd op de algemene groepsopeningstijden.',
        descriptionAlteredTimes: 'Dit zijn overschreven openingstijden',
        serviceVarietyKDV: 'Kinderdagverblijf',
        serviceVarietyVSO: 'Voorschoolse opvang',
        serviceVarietyNSO: 'Naschoolse opvang',
        overrideOpeningTimes: 'Ik wil de openingstijden overschrijven',
        timeFrom: 'Van',
        timeUntil: 'Tot',
        closed: 'Gesloten',
        additionalInfo: 'Additionele informatie vermelden onder de openingstijden voor ouders (optioneel)',
        additionalInfoNoData: 'Voer hier additionele informatie over openingstijden in'
      },
      dialog: {
        noActiveOpeningTimes: 'Deze locatie heeft geen actieve openingstijden',
        noActivePricing: 'Er staat voor deze locatie geen tarief ingesteld',
        noActiveSubscription: 'Er wordt voor deze locatie geen abonnement aangeboden',
        noSchool: 'Deze locatie heeft geen koppeling met een school',
        noActiveSchoolGroupOpeningTimes: 'Gekoppelde scholen hebben geen schoolgroepen met actieve openingstijden',
        serviceHasNoValidOpeningHours: 'Deze locatie heeft geen actieve openingstijden',
        serviceSchoolHasNoValidOpeningHours: 'Deze locatie heeft geen samenwerkingsverband met een school of de school heeft geen actieve openingstijden',
        serviceHasNoActivePriceOrSubscription: 'Er staat voor deze locatie geen tarief ingesteld of er wordt geen abonnement aangeboden.',
        errorProductSettingsButton: 'Producten instellen'
      },
      photosTitle: 'Foto\'s',
      errors: {
        updateServiceFailed: 'Er is iets misgegaan tijdens het bijwerken van de locatie',
        updateMediaFailed: 'Er is iets misgegaan tijdens het bijwerken van de foto\'s'
      }
    },
    features: {
      table: {
        columns: {
          enabled: 'Actief op locatie',
          mainFeature: 'Hoofdkenmerk',
          description: 'Kenmerk',
          persona: 'Persona'
        }
      }
    },
    products: {
      pageTitle: 'Producten',
      tableTitle: 'Boekbare producten via de Kieswijzer',
      addBookableProducts: 'Voeg boekbaar product toe aan Kieswijzer',
      table: {
        filters: {
          categoryLabel: 'Categorie',
          categoryPlaceholder: 'Kies een categorie',
          serviceVarietyLabel: 'Opvangsoort',
          serviceVarietyPlaceholder: 'Kies een opvangsoort',
          searchLabel: 'Productnaam',
          searchPlaceholder: 'Zoeken',
          noData: 'Geen items gevonden'
        },
        columns: {
          name: 'Productnaam (Flexkids)',
          category: 'Categorie',
          serviceVariety: 'Opvangsoort',
          hourlyRate: 'Uurprijs',
          vaildFrom: 'Geldig vanaf'
        },
        expanded: {
          name: 'Naam in Kieswijzer',
          textualAddition: 'Toon tekstuele toevoeging op contract en orderscherm',
          deactivateButton: 'Verberg product',
          edit: {
            dialogTitle: 'Wijzig Product in de Kieswijzer voor <b>{service}</b>',
            textualAdditionLabel: 'Tekstuele toevoeging op contract en orderscherm',
            textualAdditionPlaceholder: 'Tekstuele toevoeging...',
            cancel: 'Annuleren',
            confirm: 'Opslaan'
          },
          removeProduct: {
            title: 'Laatste boekbaar product op deze locatie.',
            description: 'Je gaat het laatste boekbaar product op deze locatie verwijderen. Zonder boekbare {serviceVariety} alle weken producten die ook nu beschikbaar zijn kan er geen prijs berekend en geboekt worden. <br/><br/>Weet je zeker dat je het laatste product wilt verwijderen? Dan zetten we de rekentool en het boeken uit voor deze locatie.',
            descriptionWithCalculationProduct: 'Je gaat het laatste boekbaar product op deze locatie verwijderen. Zonder boekbare {serviceVariety} alle weken producten die ook nu beschikbaar zijn kan er geen prijs berekend en geboekt worden. <br/><br/>Omdat dit product ook wordt gebruikt om de kinderopvangtoeslag te berekeningen voor deze locatie, zal deze locatie niet meer zichtbaar zijn in de rekentool. <br/><br/>Weet je zeker dat je het laatste product wilt verwijderen? Dan zetten we de rekentool en het boeken uit voor deze locatie.',
            cancel: 'Annuleren',
            remove: 'Verwijderen',
            NSO: 'naschoolse'
          },
          removeCalculationProduct: {
            title: 'Verwijderen product voor rekentool',
            description: 'Dit product wordt gebruikt om de kinderopvangtoeslag te berekeningen voor deze locatie. Indien je deze wenst te verwijderen wordt deze locatie niet meer zichtbaar in de rekentool.',
            cancel: 'Annuleren',
            remove: 'Verwijderen'
          }
        },
        category: {
          Unsupported: 'Niet ondersteund',
          AllWeeks: 'Alle weken',
          SchoolWeeks: 'Schoolweken'
        },
        notUniqueProductTime: 'Er bestaat al een product met deze openingstijden en die op hetzelfde moment actief is',
        notBookableProduct: 'Dit product kan niet aangeboden worden in Whyplan'
      },
      addProducts: {
        title: 'Voeg product toe aan de Kieswijzer voor',
        confirmAddProducts: 'Voeg {count} product(en) toe aan Kieswijzer',
        validationError: 'Validatie fout'
      },
      chooseCalculatorProduct: {
        title: 'Producten in Rekentool',
        subTitleKDV: 'Product in berekening (Alle weken)',
        subTitleVSO: 'Voorschoolse opvang product in berekening',
        subTitleNSO: 'Naschoolse opvang product in berekening (Alle weken)',
        description: 'Kies hier een van de actieve <b>alle weken</b> producten, waarmee ouders in de rekentool hun kinderopvangtoeslag berekenen.<br/>Dit kunnen enkel producten zijn die in bovenstaande tabel aanwezig zijn.',
        descriptionVSO: 'Kies hier een van de actieve producten, waarmee ouders in de rekentool hun kinderopvangtoeslag berekenen.<br/>Dit kunnen enkel producten zijn die in bovenstaande tabel aanwezig zijn.',
        info: 'Als hier geen <b>alle weken</b> product geselecteerd is, dan is deze ook niet zichtbaar in de rekentool.',
        productSelectPlaceholder: 'Naam van alle weken product',
        productSelectPlaceholderVSO: 'Naam van product',
        productSelectNoData: 'Geen product gevonden'
      }
    },
    controlVariables: {
      settings: 'Instellingen',
      planSettings: 'Plan instellingen',
      waitinglistManager: 'Wachtlijstmanager instellingen',
      offer: 'Aanbod',
      openingTimes: 'Openingstijden',
      flexMargin: 'Flex Marge',
      flexMarginDescription: 'Het percentage van flexcontracten met uren waar rekening mee gehouden wordt.',
      actions: 'Acties',
      calculateCapacityDescription: 'Herbereken capaciteit',
      triggerCalculateCapacity: 'Start herberekening',
      triggerCalculateCapacityError: 'Er is een fout opgetreden, probeer het later opnieuw',
      triggerCalculateCapacityAlreadyStarted: 'Er is al een herberekening gestart. Herlaad de pagina en probeer het later opnieuw',
      capacityLastCalculatedDate: 'Laatste herberekening op: <b>{date}</b>.',
      capacityLastCalculatedDateNotSet: 'Laatste herberekening op: <b>nog niet berekend</b>. Klik op de knop hiernaast om de capaciteiten te herberekenen.',
      temporarySettings: 'Tijdelijke instellingen',
      temporySettingsProductDialog: {
        title: 'Geen boekbare producten',
        description: 'Deze locatie heeft geen boekbare {serviceVariety} alle weken producten die ook nu beschikbaar zijn. Zonder boekbare producten kun je de toggle nog niet aanzetten. Boekbare producten kunnen hieronder gekozen worden.',
        NSO: 'naschoolse'
      },
      temporySettingsSubscriptionDialog: {
        title: 'Flexkidsproducten kunnen niet uitgezet worden',
        description: 'Flexkidsproducten kunnen niet uitgezet worden, vanwege oude validatie die faalt door onderstaande redenen. Sla deze instellingen eerst op voordat je het opnieuw probeert.'
      },
      useFlexkidsProducts: {
        title: 'Gebruik Flexkids producten',
        flexkidsProductsEnabled: 'Wanneer dit vinkje aanstaat wordt de nieuwe implementatie van de Flexkids producten gebruikt. Hiervoor moet er bij een locatie boekbare producten ingesteld staan onder het producten tabje.',
        flexkidsProductsDisabled: 'Wanneer deze uitstaat, wordt de oude implementatie van abonnementen gebruikt. Hierbij worden alle boekingen op deze locatie geboekt met een Whyplan product dat in Flexkids staat.',
        label: 'Gebruik Flexkids producten'
      },
      planHorizon: {
        title: 'Aanbod plan horizon',
        description: 'Geef hiermee aan hoever in de toekomst het algoritme moet plannen.',
        monthPlural: '0 maanden | {count} maand | {count} maanden'
      },
      waitingListActualisationIntervals: {
        title: 'Wachtlijst actualisatie termijnen',
        description: 'Geef hiermee aan hoeveel tijd een ouder heeft om hun plekje op de wachtlijst te behouden. De ouder krijgt twee herrineringsmails met de vraag of ze op de wachtlijst willen blijven staan voordat deze verloopt.',
        buttonTextWeeks: '0 weken | {count} week | {count} weken',
        buttonTextDays: '0 dagen | {count} dag | {count} dagen',
        buttonTextHours: '{count} uur',
        buttonTextMinutes: '{count} min',
        firstRemainder: 'Eerste mail na',
        secondRemainder: 'Tweede mail na',
        endDate: 'Wachtlijst verloopt na'
      },
      subscriptions: {
        subscriptions: 'Abonnementen aanbod',
        subscriptionsDescription: 'Geef hiermee aan welke abonnementen je op deze locatie wilt aanbieden. De tarieven worden automatisch gebaseerd op het basistarief.<br/><br/>Voeg optioneel een notitie toe voor eventuele vakantie samenwerking met andere locaties.',
        until: 'tot',
        note: 'Tekstuele toevoeging op contract en orderscherm',
        notePlaceholder: 'Tekstuele toevoeging...',
        startDate: 'Ingangsdatum',
        price: 'Prijs',
        overwritePriceDialog: {
          title: 'Weet je het zeker?',
          description: 'Je staat op het punt om alle tarieven na de ingevoerde ingangsdatum te overschrijven.',
          cancel: 'Nee ga terug',
          confirm: 'Ja, schrijf over'
        },
        removePriceDialog: {
          title: 'Weet je het zeker?',
          description: 'Je staat op het punt om de tariefstelling te verwijderen.',
          cancel: 'Nee ga terug',
          confirm: 'Ja, verwijder tarief'
        },
        customSubscriptionToggle: 'Niet gevonden wat je zocht?',
        customSubscriptionToggleDescription: 'Toon "Niet gevonden wat je zocht?" optie'
      },
      servicePricing: {
        title: 'Basistarief',
        description: 'Geef hiermee aan wat het basistarief op deze locatie is (per periode).'
      },
      serviceVarietyOpening: {
        clearTime: 'Legen'
      },
      orderProductSettings: {
        title: 'Producten aanbod',
        description: 'Producten aanbod instellingen, indien toggle aan wordt in de kieswijzer bij producten selectie de optie getoond om naar de partou site te navigeren en daar te boeken.',
        showLinkToPartouOffers: 'Niet gevonden wat je zocht?',
        showLinkToPartouOffersDescription: 'Toon "Niet gevonden wat je zocht?" optie'
      },
      placementPolicy: {
        title: 'Plaatsingsbeleid Whyplan',
        description: 'Bepaal vanaf welk gemiddelde bezettingspercentage (over de komende {amountOfMonths} maanden) het strenge- en eventueel het gematigde plaatsingsbeleid van Whyplan moet worden toegepast. Let op: peutergroepen volgen altijd het soepele plaatsingsbeleid.',
        placementPolicy: 'Plaatsingsbeleid',
        occupancyPercentageAverage: 'Gemiddelde bezettingspercentage',
        flexiblePolicy: 'Soepel beleid van (Geen impact)',
        moderatePolicy: 'Gematigd beleid van (Halve impact)',
        strictPolicy: 'Streng beleid van (Volledige impact)',
        upToAndIncluding: 't/m',
        errorMessage: {
          showStrictIsRequired: 'Het percentage voor het strenge beleid is verplicht.',
          strictLowerThanModerate: 'Het percentage voor het strenge beleid moet hoger liggen dan dat voor het gematigd beleid.'
        }
      }
    },
    waitingList: {
      waitingListDisabled: 'Automatisch aanbieden van wachtlijstplekken is momenteel uitgeschakeld.',
      goToWaitingListAnalysisPageButton: 'wachtlijst analyse pagina',
      unknownSubscriptionTooltip: 'Voor dit kind kan niet geautomatiseerd een plaats aangeboden worden.',
      unknownSubscription: 'Product onbekend',
      unknown: 'Onbekend',
      table: {
        searchLabel: 'Zoeken...',
        heading: 'Wachtlijst inschrijvingen',
        childNumber: 'KRN',
        subscriptionName: 'Product',
        reservation: 'Plaatsing',
        proposition: 'Aanbod',
        waitingListPlace: 'Wachtlijst',
        columns: {
          childId: 'Kind ID',
          childName: 'Roepnaam',
          dateOfBirth: 'Geboortedatum',
          createdAt: 'Geboekt op',
          validFrom: 'Geldig vanaf',
          serviceVarieties: 'Opvangsoort(en)',
          status: 'Status',
          priority: 'Prioriteit'
        }
      },
      waitingListPlacePriority: {
        ownstaff: '1 - Eigen personeel',
        smi: '2 - SMI',
        secondchild: '3 - Tweede kind',
        daycaretoschoolcare: '4 - KDV > BSO',
        expansion: '5 - Uitbreiding',
        waitinglistfamily: '6 - Wachtlijst gezin',
        movehouse: '7 - Verhuizing',
        new: '8 - Nieuwe inschrijving',
        changedays: '9 - Wisseling dagen',
        changegroup: '10 - Wisseling groep',
        changelocation: '11 - Wisseling locatie',
        extension: '12 - Verlenging',
        sleepingwaitinglist: '13 - Slapende wachtlijst',
        vveindication: '14 - VVE indicatie',
        povwithoutkot: '15 - POV zonder KOT'
      },
      waitingListPlaceStatus: {
        waiting: 'Wachten',
        approached: 'Benaderd',
        accepted: 'Geaccepteerd',
        cancelled: 'Geannuleerd'
      },
      waitingListPropositionStatus: {
        new: 'Nieuwe aanbieding',
        proposed: 'Uitgestuurd naar ouder',
        expired: 'Verlopen',
        cancelled: 'Afgewezen door planner',
        declined: 'Afgewezen door ouder',
        completed: 'Plaatsing definitief',
        processing: 'In behandeling bij ouder',
        revoked: 'Ingetrokken door planner'
      },
      waitingListDeleteDialog: {
        title: 'Wachtlijst boeking verwijderen',
        description: 'Selecteer hier de wachtlijst inschrijvingen die je wilt verwijderen:',
        VSO: 'Wachtlijst VSO',
        NSO: 'Wachtlijst NSO',
        KDV: 'Wachtlijst KDV',
        back: 'Terug',
        delete: 'Verwijderen'
      }
    },
    booking: {
      id: 'Boeking id'
    }
  },
  analysis: {
    menu: {
      availabilityAnalysis: 'Beschikbaarheid',
      waitingListAllocationAnalysis: 'Wachtlijst',
      waitingListPropositionAnalysis: 'Wachtlijst Nieuw'
    },
    postalCode: 'Postcode',
    dateOfBirth: 'Geboortedatum',
    startDateOfCare: 'Start van opvang',
    desiredDays: 'Gewenste opvangdagen',
    serviceVarieties: 'Soort opvang',
    locality: 'Plaatsnaam school',
    school: 'School',
    analyseButton: 'Analyseer',
    available: 'Beschikbaar',
    notAvailable: 'Niet beschikbaar',
    partlyAvailable: 'Deels beschikbaar',
    notBookable: 'Niet boekbaar',
    waitingList: 'Wachtlijst',
    noCapacities: 'Geen capaciteiten',
    noEndDate: 'Geen einddatum',
    age: 'Leeftijd',
    group: 'Groep',
    availablePlaces: 'Vrije plekken',
    extraPlaces: 'Extra plekken',
    show: 'Toon',
    more: 'meer',
    less: 'minder',
    availability: 'Beschikbaarheid',
    distance: 'Afstand',
    totalIdealPathDeviations: 'Totaal aantal ongewenste doorplaatsingen',
    totalImpact: 'Totale impact',
    totalValue: 'Totale waarde',
    daysInPeriod: 'dagen in periode',
    impactOfDay: 'Impact',
    valueOfDay: 'Waarde',
    noResult: 'Geen resultaat',
    invalidPostalCode: 'Ongeldige postcode',
    invalidSchool: 'Deze school heeft geen contract',
    noDaysSelected: 'Selecteer minimaal één dag',
    noServiceVarietySelected: 'Selecteer een opvangsoort',
    noSchoolSelected: 'Selecteer een school',
    kdvWrongServiceKindSelected: 'Het gekozen opvangtype is niet geldig voor KDV',
    bsoWrongServiceKindSelected: 'Het gekozen opvangtype is niet geldig voor BSO',
    availableFrom: 'Datum van',
    availableUntil: 'Datum tot',
    baseCapacity: 'Basis capaciteit',
    currentCapacity: 'Huidige capaciteit',
    extraCapacity: 'Extra capaciteit',
    totalCapacity: 'Totale capaciteit',
    occupiedCapacity: 'Bezette capaciteit',
    impact: 'Impact',
    impactPerDay: 'Impact per dag',
    totalImpactPerDay: 'Totale impact per periode',
    amountOfDayOfWeekInPeriod: 'Aantal dagen',
    extraCapacityTypeLabel: 'Plaatsmaakactie (extra capaciteit)',
    kilometers: 'km',
    dayPriorityToggle: 'Gebruik prioriteit',
    extraCapacityType: {
      CancallationMargin: 'Opzeggingsmarge',
      MoveLowerSeatUp: 'PABoven',
      MoveHigherSeatDown: 'PABeneden',
      MoveHigherSeatDownExtra: 'PABenedenExtra',
      ExtraEmployee: 'Extra medewerker'
    },
    days: [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag'
    ],
    chosenPath: 'Gekozen pad voor de beschikbare geselecteerde dagen',
    placementPolicy: {
      header: 'Plaatsingsbeleid en de gemiddelde bezettingspercentage per groep per dag',
      table: {
        group: 'Groep',
        day: 'Dag',
        placementPolicy: 'Plaatsingsbeleid',
        occupancyPercentageAverage: 'Gemiddelde bezettingspercentage (over de komende 6 maanden)'
      }
    },
    table: {
      day: 'Dag',
      age: 'Leeftijd',
      value: 'Waarde',
      idealPathDeviations: 'Ongewenste doorplaatsingen',
      impact: 'Impact',
      group: 'Groep',
      available: 'Plek beschikbaar'
    }
  },
  waitingListPropositionAnalysis: {
    goToWaitingListPageButton: 'Wachtlijst pagina',
    calculationDate: 'Datum van berekenen',
    calculationTime: 'Berekenduur',
    maxStartDate: 'Maximale startdatum',
    createdWaitingListPropositions: 'Gemaakte wachtlijstaanbiedingen',
    placementPolicy: {
      header: 'Plaatsingsbeleid',
      toddlerGroup: 'Let op: Plaatsingen op peutergroepen verlopen altijd volgens een soepel plaatsingsbeleid, ongeacht het getoond plaatsingsbeleid in de onderstaande tabel.',
      description: 'Ingestelde niveaus van plaatsingsbeleid op basis van gemiddelde bezettingspercentages',
      flexiblePercentage: 'Soepel planningsbeleid van {percentageFrom}% t/m {percentageUpToAndIncluding}%',
      moderatePercentage: 'Gematigd planningsbeleid van {percentageFrom}% t/m {percentageUpToAndIncluding}%',
      strictPercentage: 'Streng planningsbeleid van {percentageFrom}% t/m {percentageUpToAndIncluding}%'
    },
    round: 'Ronde',
    children: 'Kinderen',
    name: 'Naam',
    number: 'Nummer',
    priority: 'Prioriteit',
    desiredDaysOfCare: 'Wil opvang op',
    calculatedDesiredDaysOfCare: 'Opvang berekend voor',
    dateOfBirth: 'Geboortedatum',
    desiredStartDate: 'Wil opvang vanaf',
    registrationDate: 'Aanmelddatum',
    careType: 'Opvangtype',
    dayOfWeek: 'Dag',
    hasPriority: 'Heeft prioriteit',
    holePoints: 'Gatenpunten',
    startsOn: 'Start op',
    impact: 'Impact',
    value: 'Waarde',
    possibleDayCombinations: 'Mogelijke dag combinaties',
    propositions: 'Aanbiedingen',
    childName: 'Kindnaam',
    prioPoints: 'Priopunten',
    possiblePropositions: 'Mogelijke aanbiedingen',
    daysOfWeek: 'Dagen',
    total: 'Totaal',
    wishedStartDateAnalysisResultId: 'Analyse vanaf eerst mogelijke startdatum',
    offeredAnalysisResultId: 'Analyse vanaf aangeboden startdatum',
    page: {
      chooseLocation: 'Kies een locatie',
      loading: 'Wachtlijst resultaten aan het ophalen...',
      noResult: 'Geen resultaat',
      noResultDescription: 'Er zijn nog geen wachtlijst berekeningen uitgevoerd voor deze locatie'
    },
    filter: {
      allocatedResultsOnly: 'Toon alleen resultaten met uitgegeven wachtlijsten',
      from: 'Vanaf',
      until: 'Tot en met',
      search: 'Zoeken'
    }
  },
  waitingListAllocationAnalysis: {
    goToWaitingListPageButton: 'Wachtlijst pagina',
    waitingListAnalysisPage: {
      chooseLocation: 'Kies een locatie',
      loading: 'Wachtlijst resultaten aan het ophalen...',
      noResult: 'Geen resultaat',
      noResultDescription: 'Er zijn nog geen wachtlijst berekeningen uitgevoerd voor deze locatie'
    },
    waitingListAnalysisPageFilter: {
      allocatedResultsOnly: 'Toon alleen resultaten met uitgegeven wachtlijsten',
      from: 'Vanaf',
      until: 'Tot en met',
      search: 'Zoeken'
    },
    bookingWaitingListPropositionPanel: {
      child: 'Kind',
      registrationDate: 'Aanmelddatum',
      requestDate: 'Gewenste startdatum',
      priority: 'prioriteit',
      waitingListDays: 'Wachtlijstdagen',
      allPossibleStartDates: 'Alle mogelijke startdatums'
    },
    waitingListAllocationCard: {
      calculationDate: 'Datum van berekenen',
      elapsed: 'Berekenduur',
      maxStartDate: 'Maximale startdatum',
      bookedWaitingListProposition: 'Voorstel aangeboden aan',
      startDate: 'Startdatum',
      bookedDays: 'Geboekte dagen',
      noWaitingListAllocations: 'Geen actieve wachtlijsten'
    },
    waitingListPropositionTable: {
      startDate: 'Startdatum',
      impact: 'Impact',
      availableDays: 'Beschikbare dagen'
    }
  },
  dropzone: {
    dropzoneMessageTitle: 'Sleep een bestand om een foto te uploaden!',
    dropzoneMessageText: '...of klik hier om een bestand van je computer te selecteren',
    dropzonePreviewErrorIncorrectType: 'Dit bestandstype wordt niet ondersteund',
    dropzonePreviewErrorFileTooLarge: 'Dit bestand is te groot, selecteer een ander',
    dropzoneLoadingText: 'Bezig met laden...',
    dropzoneMaxFilesWarning: 'Overschrijdt het maximum van 9 bestanden'
  },
  forbidden: {
    title: 'Verboden',
    message: 'U heeft niet genoeg permissies om de opgevraagde pagina in te zien.'
  },
  dayTable: {
    vso: 'VSO',
    nso: 'NSO',
    kdv: 'KDV',
    placements: 'Plaatsingen',
    propositions: 'Aanbieding',
    waitingLists: 'Wachtlijstdagen',
    OriginalWaitingLists: 'Originele Wachtlijstdagen'
  }
}

export default nl
